import { useStaticQuery, graphql } from "gatsby"
import { useGetPropertiesQuery as useGetPropertiesTokkoQuery } from "../redux/middlewareTokkoApi/properties"
import { useGetPropertiesQuery as useGetPropertiesMediacoreQuery } from "../redux/mediacore/properties"
import {
  makeCoordinatesMediacore,
  makeFormDataByFilters,
  makeFormDataByFiltersMediacore,
  makeParamsByFiltersMediacore,
  makeParamsCoordinates,
  makeParamsGetByFilters,
} from "../helpers/helper.filters"

function useQueryProperties({
  filters = {},
  coordinates = [],
  polygonCoordinates = [],
  orders={},
  offset = ''
}) {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        keys {
          tokko
        }
      }
    }
  `)

  // const { data: propertiesOld, isLoading: isLoadingProperties, isFetching: isFetchingProperties } = useGetPropertiesQuery({
  //   "API_KEY":realEstate.keys.tokko,
  //   "params_get":makeParamsGetByFilters(orders,offset,polygonCoordinates) + makeFormDataByFilters(filters,coordinates,polygonCoordinates),
  //   "body":makeParamsCoordinates(coordinates,polygonCoordinates)
  // });

  const params_get = realEstate.keys.tokko
    ? makeParamsGetByFilters(orders,offset,polygonCoordinates) + makeFormDataByFilters(filters, coordinates, polygonCoordinates)
    : makeParamsByFiltersMediacore(filters,polygonCoordinates,coordinates)

  const {
    data: properties,
    isLoading,
    isError,
    isFetching,
  } = realEstate.keys.tokko //EDIT
    ? useGetPropertiesTokkoQuery({
        API_KEY: realEstate.keys.tokko,
        params_get: params_get,
        body: makeParamsCoordinates(coordinates,polygonCoordinates),
      })
    : useGetPropertiesMediacoreQuery({
        CLIENT_ID: '20',
        params_get: params_get,
        body:makeFormDataByFiltersMediacore(filters, coordinates, polygonCoordinates),
        coordinates:makeCoordinatesMediacore(coordinates, polygonCoordinates)
      })

  return {
    data:properties,
    properties:properties?.objects,
    isLoading,
    isError,
    isFetching,
  }
}

export default useQueryProperties
