import { Link } from 'gatsby';
import React, {useEffect}  from 'react';
import Searcher from '../search'
import { connect } from 'react-redux';
import OwlCarousel from 'react-owl-carousel3';
import { getDevelopmentsAction } from '../../redux/developmentsDucks';
import {getTotalListFilters} from '../../redux/propertiesDucks'
import { useLocation } from "@reach/router";

import Loading from '../Loading'
import BannerTop from "../Global/Modules/MediaPush/BannerTop";

//Helpers
import { getLocation, getLastFeatured } from '../../helpers/helper.developments';
import { getCover } from '../../helpers/helper.rendering';
import { graphql, useStaticQuery } from 'gatsby';
import useQueryDevelopments from '../../hooks/useQueryDevelopments';

const Home_Main = ({developments,loading,api_key,dispatch,actions}) =>  {

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              slogan
              main_image
          }
    }`)

    useEffect(() => {
        if(api_key){
            dispatch(getDevelopmentsAction()) // Get all developments
            dispatch(getTotalListFilters()) // Get all developments
        }
    },[api_key])

    const makeLink = (development) => {
        if(development){
            return "/emprendimientos/" + development.id 
        }
    }

    const location = useLocation()

    useEffect(() => {
        if((location.pathname.length === 1 && location.pathname === '/')) {
            checkActionsTop()
        }
    },[location])

    const checkActionsTop = () => {
        if (actions && actions.length > 0) {
            const filteredActions = actions.filter(a => a.small_type === "VEN" || a.small_type === "IEN" || a.small_type === "SIE").map(a => [a.section, a.small_type].join())
            // console.log(filteredActions); - // ['Inicio,VEN']
            if (filteredActions.length > 0) {
                const filteredbyPage = filteredActions.filter(p => p.includes("Inicio"))
                return (filteredbyPage.length > 0)
                    ? ((filteredbyPage[0].split(',')[0] === "Inicio") || null) : null
            }
        } else {
            return null
        }
    }

    const { allDevelopmentsData, isLoading } = useQueryDevelopments();

    const isSSR = typeof window === "undefined";


    return true ? (
        <>
            { checkActionsTop() ? 
                <>
                    <BannerTop page="inicio"/>
                    <Searcher slogan={realEstate?.slogan} />
                </>
             : (
            //     !allDevelopmentsData?.length && !getLastFeatured(allDevelopmentsData) ? (
            //         <div id="home-main" class="banner-main">
            //             <div class="item d-flex justify-content-center text-center align-items-center">
            //                 <div class="opacity"></div>
            //                 <div class="container" style={{zIndex:"1"}}>
            //                     <div class="row align-items-center text-center justify-content-center">
            //                         <div class="col-12 pb-5">
            //                             <p>
            //                                 {developments.data && getLocation(getLastFeatured(allDevelopmentsData),true)[0]}
            //                                 <br />
            //                                 {developments.data && getLocation(getLastFeatured(allDevelopmentsData),true)[1]}
            //                             </p> 
            //                             <h1>{developments.data && getLastFeatured(allDevelopmentsData).name}</h1>
            //                             <Link to={developments.data && makeLink(getLastFeatured(allDevelopmentsData))} class="btn btn-blue mb-5">[ DESCUBRILO ]</Link>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //             <Searcher />
            //         </div>
            //     )
            // :
                    <div id="home-main" class="banner-main" >
                        <div class="item d-flex justify-content-center text-center align-items-center">
                            <div class="opacity"></div>
                            <div class="container" style={{zIndex:"1"}}>
                                <div class="row align-items-center text-center justify-content-center">
                                    <div class="col-12">
                                        <h1 className="text-center mt-5 mb-0">
                                            {realEstate?.slogan}
                                        </h1> 
                                        {!isSSR && <Searcher />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> )}
        </>
    ):''
    // <Loading absolute={true} />
}

export default connect(state => ({
    developments: state.developments.developments,
    loading: state.developments.loading,
    api_key: state.settings.keys.tokko,
    actions: state.actions.actions
}),null)(Home_Main);