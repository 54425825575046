import React,{useRef, useEffect, useState} from "react"
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from "@reach/router";



const PreguntasFrecuentes = () => {
    const { realEstate } = useStaticQuery(graphql`
            query {
                realEstate {
                    sections {
                        home {
                            frequent_questions {
                                title
                                subtitle
                                items 
                                button{
                                    value
                                    link
                                }
                            }
                        }
                    }
                }
    }`)

    const staticBody = () => {
        var body = document.body;
        body.classList.remove("overflow-hidden");
    }

    return realEstate.sections && (
        <section class="questions-home">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <span class="section-name">{realEstate.sections.home.frequent_questions.title}</span>
            </div>
            <div class="col-12">
                <h2>{realEstate.sections.home.frequent_questions.subtitle}</h2>
            </div>  
            <div class="col-12">
                <p>{realEstate.sections.home.frequent_questions.items[0]} <br class="d-none d-lg-block"/> 
                {realEstate.sections.home.frequent_questions.items[1]} <br class="d-none d-lg-block"/> 
                {realEstate.sections.home.frequent_questions.items[2]} <br class="d-none d-lg-block"/> 
                {realEstate.sections.home.frequent_questions.items[3]}</p>
            </div>
            <div class="col-12">
                <a href={realEstate.sections.home.frequent_questions.button.link} class="btn-global btn-secondary">{realEstate.sections.home.frequent_questions.button.value}</a>
            </div>
        </div>
    </div>
</section>
        

    )
}

export default PreguntasFrecuentes