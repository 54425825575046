import React, { useRef, useEffect } from "react"
import Card from "../property-card"
import { graphql, useStaticQuery } from "gatsby"
import OwlCarousel from "react-owl-carousel3"
import Breadcrumbs from "../breadcrumbs"

//Redux
import { connect } from "react-redux"

//Dispatch
import { getFeaturedPropertiesAction } from "../../redux/propertiesDucks"
import { useGetFeaturedPropertiesQuery } from "../../redux/middlewareTokkoApi/properties"
import useQueryProperties from "../../hooks/useQueryProperties"

const options = {
  loop: true,
  smartSpeed: 1000,
  stagePadding: 10,
  center: true,
  margin: 10,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 2,
    },
    1000: {
      items: 2,
    },
  },
}

const Properties = ({ properties, api_key, dispatch }) => {
  const slider = useRef()
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        keys {
          tokko
        }
        sections {
          home {
            properties {
              title
              button {
                value
                link
              }
            }
          }
        }
      }
    }
  `)

  const { data: featuredPropertiesData, isLoading: isLoadingProperties } =
    realEstate.keys.tokko
      ? useGetFeaturedPropertiesQuery({ API_KEY: realEstate.keys.tokko })
      : useQueryProperties({ filters: {} })

  return true ? (
    <>
      <section id="featured-props" className="d-block d-lg-none">
        <div class="container-fluid">
          <div class="row">
            {/* <div className="col-lg-12">
                        <Breadcrumbs
                            props={[
                            { name: realEstate.name, route: "/", location: "" },
                            { name: "Home", route:   "", location: ""},
                            ]}
                        />
                    </div> */}
            <div class="col-12">
              <h2 class="section-title pb-5 text-center">
                {realEstate.sections.home.properties.title}
              </h2>
            </div>
          </div>
        </div>
        <div className="props d-flex align-items-center justify-content-center my-4">
          {isLoadingProperties ? (
            <div className="row mx-4">
              {[1].map(index => (
                <div className="col-12">
                  <Card key={index} basic={true} skeleton={true} />
                </div>
              ))}
            </div>
          ) : (
            <>
              <OwlCarousel
                ref={slider}
                {...options}
                className="overflow-hidden"
              >
                {featuredPropertiesData?.objects.map((prop, index) => (
                  <Card key={index} basic={true} property={prop} />
                ))}
              </OwlCarousel>
              <div className="arrows">
                <div className="arrow" onClick={() => slider.current.prev()}>
                  {" "}
                  {"<"}{" "}
                </div>
                <div className="arrow" onClick={() => slider.current.next()}>
                  {" "}
                  {">"}{" "}
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <section id="featured-props" class="pt-lg-5 d-none d-lg-block">
        <div class="container pb-5 pr-0 pr-lg-3">
          <h2 class="section-title py-5 text-center">
            {realEstate.sections.home.properties.title}
          </h2>
          <div class="props my-4">
            <div id="checkWidthHome" class="row mx-0 mx-lg-3">
              {/* Sort and reverse props to show Venta firs */}
              {/* {featuredPropertiesData.objects.map((prop,i) => (  | Original Code */}
              {isLoadingProperties ? (
                <div className="row">
                  {[1, 2, 3].map(index => (
                    <div className="col-lg-4">
                      <Card key={index} basic={true} skeleton={true} />
                    </div>
                  ))}
                </div>
              ) : (
                featuredPropertiesData.objects.map(
                  (prop, i) =>
                    i < 3 && (
                      <div class="col-md-4 px-0 px-lg-2">
                        <Card key={i} basic={true} property={prop} />
                      </div>
                    )
                )
              )}
              {/* <div className="props d-flex align-items-center justify-content-center my-4">
                            <OwlCarousel ref={slider}
                                {...options}
                                className="overflow-hidden">
                                {featuredPropertiesData.objects.map((prop,index) => (
                                    <Card key={index} basic={true} property={prop}/>
                                ))}
                            </OwlCarousel>
                            <div className="arrows">
                                <div className="arrow" onClick={() => slider.current.prev()}> {'<'} </div>
                                <div className="arrow" onClick={() => slider.current.next()}> {'>'} </div>
                            </div>
                        </div> */}

              {/* <?php $destacados = Tokko_Destacados(3); 
                        if(count($destacados['objects']) > 0) { 
                            foreach ($destacados['objects'] as $propiedad) { ?>
                                <div class="col-md-4 px-0 px-lg-2">
                                    <?php include('components/property-card.php'); ?>
                                </div>                    
                            <?php } 
                        }?> */}
            </div>
          </div>
          <div class="row text-center">
            <div class="col-12 mt-5">
              <a
                class="underlined-link mx-auto text-center "
                href={realEstate.sections.home.properties.button.link}
              >
                {realEstate.sections.home.properties.button.value}
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  ) : (
    ""
  )
}

export default connect(
  state => ({
    properties: state.properties.featured,
    api_key: state.settings.keys.tokko,
  }),
  null
)(Properties)
