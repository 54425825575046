import React from 'react'
import { getTextShort, getDateTime, getCompleteName } from '../helpers/helper.novelty'
import { navigate } from "gatsby"
import { clearNoveltyAction } from '../redux/noveltiesDucks'
import { connect } from 'react-redux'

const NoveltyCard = (props) => {

    const hidenTags = props?.hidenTags
    const hidenAuthor = props?.hidenAuthor
    const isHome = props?.isHome
    const {novelty} = props
    const dispatch = props.dispatch

    return  (
        <div class={"novelty-card custom-border-radius " + (isHome ? 'home' : '')}>
           <img src={novelty.image} alt="Novedad" />
           <div className='div-content d-flex flex-column p-4 text-start'>
               <div className="div_texto">
                    <span className='date'>{getDateTime(novelty.date_published)}</span>
                    <h2 className='title mt-4' >{getTextShort(novelty.title, 70)}</h2>
                    <p className={'description ' + (isHome ? 'mt-5':'mt-3')}>{getTextShort(novelty.headline,isHome ? 100 : 100)}</p>
               </div>
               <div className={"div_button col-12 text-center mt-auto d-lg-flex " + (!hidenTags ? "justify-content-between" : "justify-content-center")}>
                   <div className={"div_etiquetas flex-wrap " + (hidenTags && "d-none" )}>
                       {novelty.tags.map((tag, index) => (
                            <span className='pill btn btn-primary me-2 mt-1 mt-lg-0' key={index}>{tag.name}</span>
                       ))}
                   </div>
                    <a onClick={(e) => e.preventDefault() + dispatch(clearNoveltyAction()) + navigate("/novedades/" + novelty.id + "/" + novelty.slug)} class="btn btn-primary mt-3 mt-lg-0">LEER NOVEDAD <span className="d-none d-lg-inline">COMPLETA</span></a>
                </div>
               <span className={'by-name' + (hidenAuthor ? ' d-none' : '')}>por {getCompleteName(novelty)}</span>
           </div>
        </div>
    )
}

export default connect(state => ({
  }),null)(NoveltyCard);