import React from "react"
import { graphql, useStaticQuery } from 'gatsby';
// import VisibilitySensor from 'react-visibility-sensor';
// import CountUp from 'react-countup';
import img from '../../images/about-img.webp'


const About = () => {
    const { realEstate } = useStaticQuery(graphql`
            query {
                realEstate {
                    sections {
                        home {
                          about{
                            title
                            subtitle
                            body
                            button{
                                value
                                link
                            }
                          }
                          
                        }
                    }
                }
    }`)

    return realEstate.sections && (
        // <section class="about-us py-5 mt-5" style={{backgroundImage:"url(" + realEstate.sections.home.about.image + ""}}>
        //     <div class="opacity"></div>
        //     <div class="container-fluid py-5">
        //         <div class="row">
        //             <div class="col-lg-2">
        //                 <p class="section-name">{realEstate.sections.home.about.title}</p>
        //             </div>
        //             <div class="col-lg-10">
        //                 <h2>{realEstate.sections.home.about.paragraph}</h2>
        //                 {/* <h2>SOMOS UN EQUIPO PROFESIONAL, MULTIDISCIPLINARIO,<br class="d-none d-lg-block" /> EMPÁTICO Y RESOLUTIVO EN EL MUNDO DEL REAL ESTATE.</h2> */}
        //             </div>
        //             <div class="col-10 mt-lg-5 offset-lg-2">
        //                 <Link to={realEstate.sections.home.about.buttons[0].link} class="btn btn-red">{realEstate.sections.home.about.buttons[0].value}</Link>
        //             </div>
        //             {realEstate.sections.home.about.items.length > 1 &&
        //                 <div class="col-lg-10 offset-lg-2 pt-5 mt-5">
        //                     <div class="row py-lg-5">
        //                         <div class="col-lg-5 pr-lg-4">
        //                             <div class="item">
        //                                 <div class="row no-gutters">
        //                                     <div class="col-6 col-lg-6 text-end">
        //                                         <h1 class="hyper d-flex justify-content-end">+
        //                                             <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
        //                                                 {({ isVisible }) => (
        //                                                 <span class="counter-value one">
        //                                                     {isVisible ? <CountUp end={realEstate.sections.home.about.items[0].value} /> : null}
        //                                                 </span>
        //                                                 )}
        //                                             </VisibilitySensor>
        //                                         </h1>
        //                                     </div>
        //                                     <div class="col-6 col-lg-6 px-3">
        //                                         <p>{realEstate.sections.home.about.items[0].paragraph}</p>
        //                                         {/* <p>AÑOS DE EXPERIENCIA <br class="d-none d-lg-block" />EN EL MERCADO <br class="d-none d-lg-block" />INMOBILIARIO.</p> */}
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                             <div class="col-lg-7">
        //                                 <div class="item" id="counter">
        //                                     <div class="row no-gutters">
        //                                         <div class="col-7 text-end">
        //                                             <h1 class="hyper d-flex justify-content-end">+
        //                                                 <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
        //                                                     {({ isVisible }) => (
        //                                                     <span class="counter-value one">
        //                                                         {isVisible ? <CountUp end={realEstate.sections.home.about.items[1].value} /> : null}
        //                                                     </span>
        //                                                     )}
        //                                                 </VisibilitySensor>
        //                                             </h1>
        //                                         </div>
        //                                         <div class="col-5 px-3">
        //                                             <p>{realEstate.sections.home.about.items[1].paragraph}</p>
        //                                             {/* <p>OPERACIONES INMOBILIARIAS <br class="d-none d-lg-block" />REALIZADAS.</p> */}
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>

        //                     </div>
        //                 </div>
        //         }
        //         </div>

        //     </div>
        // </section>
        <section class="about-us pt-4 pt-lg-5 pb-4">
            <div class="container py-5">
                <div class="row">
                    <div class="col-12 d-block d-lg-none text-center">
                        <span class="section-name">{realEstate.sections.home.about.title}</span>
                        <h2 class="mt-lg-5">
                            {realEstate.sections.home.about.subtitle[0] }
                            <br />
                            {realEstate.sections.home.about.subtitle[1] }
                        </h2>
                    </div>
                    <div class="col-lg-6">
                        <img src={img} alt=""/>
                    </div>
                    <div class="pl-lg-3 col-lg-6 d-flex align-items-start flex-column">
                        <span class="section-name d-none d-lg-block">{realEstate.sections.home.about.title}</span>
                        <h2 class="mt-lg-5 d-none d-lg-block">
                            {realEstate.sections.home.about.subtitle[0] }
                            <br />
                            {realEstate.sections.home.about.subtitle[1] }
                        </h2>
                        <p class="mt-lg-auto mt-5 mb-lg-auto mb-0 text-center text-lg-start">
                            {realEstate.sections.home.about.body[0]} <br className="d-block-1700" />
                            {realEstate.sections.home.about.body[1]} <br className="d-block-1700" />
                            {realEstate.sections.home.about.body[2]} <br className="d-block-1700" />
                            {realEstate.sections.home.about.body[3]} <br className="d-block-1700" />
                            {realEstate.sections.home.about.body[4]} <br className="d-block-1700" />
                            {realEstate.sections.home.about.body[5]} <br className="d-block-1700" />
                        </p>
                        <a class="btn-global btn-secondary m-auto m-lg-0 mt-5 mt-lg-auto" href={realEstate.sections.home.about.button.link}>{realEstate.sections.home.about.button.value}</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About