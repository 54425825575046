import { Link } from "gatsby"
import React,{ useEffect } from "react"
import NoveltyCard from "../novelty-card";
import {getNoveltiesAction} from "../../redux/noveltiesDucks"
import {getThree,orderbyDate} from '../../helpers/helper.novelty'
import ContentLoader from "react-content-loader"
import { connect } from "react-redux";

const Novelties = ({loading,grid_novelties,dispatch}) => {

    useEffect(() => {
        dispatch(getNoveltiesAction())
    },[])

    return grid_novelties.length > 0 && (
        <section className="novelties py-2">
            {/* <div className="opacity"></div> */}
            <div className="container py-5">
                <p className="section-name text-center mb-4">NOVEDADES</p>
                <h2 className="subtitle text-center">Mantenete al tanto de las últimas tendencias.</h2>
                <div className="row py-5">
                    { loading ? [1,2,3].map((count,index) => (
                            <div className="mb-4 mb-lg-0 col-12 col-lg-4" key={index}>
                                <div className='me-3 d-inline'>
                                    <ContentLoader 
                                        speed={2}
                                        width={"100%"}
                                        height={"46vh"}
                                        viewBox="0 0 100% 46vh"
                                        backgroundColor="#f3f3f3ee"
                                        foregroundColor="#ecebebee">
                                        <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" /> 
                                    </ContentLoader>
                                </div>
                            </div>
                        )) : orderbyDate(getThree(grid_novelties)).map((novelty,index) => (
                            <div className="mb-4 mb-lg-0 col-12 col-lg-4" key={index}>
                                <NoveltyCard isHome={true} novelty={novelty} hidenTags={true} hidenAuthor={true}/>
                            </div> ))}
                </div>
                <div class="row text-center">
                    <div class="col-12 mt-5">
                        <Link to="/novedades" className="btn btn-secondary btn-global custom-border-radius text-uppercase">Ver todas las novedades</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default connect(state => ({
    loading: state.novelties.loading,
    grid_novelties: state.novelties.grid_novelties,
  }),null)(Novelties);