import React from "react"
import Card from "../development-card"
import { graphql, useStaticQuery } from "gatsby"

import { connect } from "react-redux"

import { getFeatured } from "../../helpers/helper.developments"
import useQueryDevelopments from "../../hooks/useQueryDevelopments"

const developments = () => {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        sections {
          home {
            developments {
              subtitle
              sup_title
              title
              button {
                link
                value
              }
            }
          }
        }
      }
    }
  `)

  const { allDevelopmentsData, isLoading } = useQueryDevelopments()

  return true ? (
    <section id="featured-devs">
      {/* <div className="container-fluid pt-lg-5 my-lg-5 ">
                <h1 className="title-2 text-center">{realEstate.sections.home.developments.sup_title}</h1>
            </div> */}
      <div class="container-fluid pt-3 mt-lg-3 pt-lg-3">
        <h2 class="section-title py-5 text-center">
          {realEstate.sections.home.developments.title}
        </h2>
      </div>
      <div class="devs mt-4 pb-5">
        <div class="container-fluid">
          <div class="row no-gutters">
            {!isLoading
              ? getFeatured(allDevelopmentsData).map(development => (
                  <div class="col-md-4 mb-4 mb-lg-0 p-lg-3">
                    <Card development={development} />
                  </div>
                ))
              : [1, 2, 3, 4, 5, 6].map(() => (
                  <div class="col-md-4 mb-4 mb-lg-0 p-lg-3">
                    <Card skeleton={true} />
                  </div>
                ))}
          </div>
          <div class="row text-center">
            <div class="col-12 my-5">
              <a
                href={realEstate.sections.home.developments.button.link}
                className="underlined-link mx-auto text-center"
              >
                {realEstate.sections.home.developments.button.value}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    ""
  )
}

export default connect(
  state => ({
    developments: state.developments.developments,
  }),
  null
)(developments)
